@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.ttf");
  font-weight: 400;
}

body {
  min-height: 100vh;
  font-family: 'Inter';
}

.checkBoxParent {
  position: relative;
  display: inline-block;
  text-align: left;
  opacity: 1;
  direction: ltr;
  border-radius: 12px;
  transition: opacity 0.25s ease 0s;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

.checkBoxBG {
  height: 24px; 
  width: 56px; 
  margin: 0px; 
  position: relative; 
  background: rgb(136, 136, 136); 
  border-radius: 12px; 
  cursor: pointer; 
  transition: background 0.25s ease 0s;
}

.checkBoxChildren {
  height: 24px; 
  width: 34px; 
  position: relative; 
  opacity: 0; 
  pointer-events: none; 
  transition: opacity 0.25s ease 0s;
}

.checkBoxChildren2 {
  height: 24px; 
  width: 34px; 
  position: absolute; 
  opacity: 1; 
  right: 0px; 
  top: 0px; 
  pointer-events: none; 
  transition: opacity 0.25s ease 0s;
}

.switchHandle {
  height: 22px; 
  width: 22px; 
  background: rgb(255, 255, 255); 
  display: inline-block; 
  cursor: pointer; 
  border-radius: 50%; 
  position: absolute; 
  transform: translateX(1px); 
  top: 1px; 
  outline: 0px; 
  border: 0px; 
  transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
}

.inputCheckbox {
  border: 0px; 
  clip: rect(0px, 0px, 0px, 0px); 
  height: 1px; 
  margin: -1px; 
  overflow: hidden; 
  padding: 0px; 
  position: absolute; 
  width: 1px;
}

summary::marker {
  content: "";
}
